import "../scss/app.scss";
import Vue from "vue";
import * as bootstrap from "bootstrap";
import axios from "axios";
const carousels = require("./carousels");

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    itemDetail: [],
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();

    var myModal = new bootstrap.Modal(
      document.getElementById("currypanAwardModal")
    );
    myModal.show();
  },
  methods: {
    async curseDetail(postType, postID) {
      var myModal = new bootstrap.Modal(document.getElementById("modalDetail"));
      await axios
        .get(`/wp-json/wp/v2/${postType}`, { params: { include: postID } })
        .then((res) => {
          this.itemDetail = res.data;
        });
      myModal.show();
      carousels.carouselSingle();
    },
  },
});
